const experience = {
    workday: {
        name: 'Workday',
        location: 'Portland, OR',
        title: 'Associate Software Development Engineer',
        from: 'May 2023',
        to: 'present',
        info: [
            'Developing the front-end of an internal Workday product used by many users to efficiently test and deliver high-quality code.'
        ]
    },
    hexaware: {
        name: 'Hexaware',
        location: 'McLean, VA',
        title: 'Associate Software Engineer',
        from: 'February 2022',
        to: 'May 2023',
        info: [
            'Deployed to a project in service to a client. Developed creative and effective web solutions for real estate organization using Java, JavaScript, and SQL',
            'Collaborated with my team to expedite the testing and deployment process of several intranet applications. This was achieved using Cucumber, JUnit, and other testing tools and frameworks in an Agile environment',
            'Assisted external teams in the development and testing of several microservices used to process large amounts of data',
            'Designed and developed automated tests for internal API using XML spreadsheet input data.'
        ]
    },
    castle_logistics: {
        name: 'Freelance',
        location: 'Remote, WA',
        title: 'Full Stack Web Developer',
        from: 'June 2021',
        to: 'January 2022',
        info: [
            'Worked closely with clients and a small team to design and develop a responsive web application for both mobile and desktop platforms',
            'Implemented key features such as fillable forms, user authentication (login and registration), and integrated email systems',
            'Designed and developed the front end using the Java Spring framework and managed the back end with MySQL',
            'Successfully deployed the application on AWS, ensuring scalability and reliability'
        ]
    }
};

export default experience;
